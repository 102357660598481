$navColor: #465053;
$navColorLight: #637075;
$navColorDark: #2F3537;
$navTextColor: #F4F2F1;
$backgroundColor: #D5D0CD;
$backgroundColorLight: #ECEAE9;
$backgroundColorDark: #CAC3BF;
$textColor: #1F2325;
$linkColor: #637075;

html {
    min-height: 100%;
}

body {
  background: $backgroundColor;
  background: linear-gradient(90deg, $backgroundColorLight 0%, $backgroundColorLight 17rem, $backgroundColor 37rem, $backgroundColor 70%, $backgroundColorDark 100%);
  color: $textColor;
  font-family: Georgia, Georgia, serif;
}

.page-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3rem;
}

.leftbar {
    color: $navTextColor;
    position: fixed;
    left: 0;
    top: 0;
    width: 17rem;
    height: 100%;
    background-color: $navColor;
    background: linear-gradient(135deg, $navColorLight 0%, $navColorDark 100%);
}

.leftbar-holder {
    position: relative;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 85%;
}

.leftbar-title {
    font-size: 2.1rem;
    margin-bottom: 1.5rem;
    letter-spacing: .15rem;
    text-shadow: .1rem .1rem 1rem rgba(29,24,22,1);
}

.leftbar-description {
    margin-bottom: 3rem;
    text-shadow: .1rem .1rem .3rem rgba(29,24,22,1);
    color: #C7BDB8;
}

.leftbar-navigation {
    font-size: 1.2rem;
    line-height: 4em;
    text-shadow: .1rem .1rem .5rem rgba(29,24,22,1);
    letter-spacing: .1rem;
}

.horizontal-navigation {
    display: none;
}

.horizontal-navigation select {
    width: 8rem;
    margin: .6rem 0 .6rem 0;
    max-width: 35rem;
    height: 1.8rem;
    font: inherit;
}

.leftbar a {
    text-decoration: none;
    font:inherit;
    color: inherit;
}

.content {
    margin-left: 20rem;
    margin-top: 3rem;
    margin-bottom: 6rem;
    max-width: 50rem;
    padding: 0 10%;
    font-size: 1.2rem;
}

.pad {
    padding-top: 10vh;
}

.content a {
    color: $linkColor;
}

@media only screen and (max-height: 600px) {

.horizontal-navigation {
    display: inline-block;
}

.vertical-navigation {
    display: none;
}
}

@media only screen and (max-width: 1000px) {

body {
    background: linear-gradient(180deg, $backgroundColorLight 0%, $backgroundColor 18rem, $backgroundColor 90%, $backgroundColorDark 100%);
}

.leftbar {
    position: absolute;
    height: 3rem;
    width: 100%;
}

.content {
    margin-left: 0;
    margin-top: 6rem;
}

.pad {
    padding-top: 0;
}

.horizontal-navigation {
    display: inline;
    height: 100%;
    line-height: 3rem;
}

.vertical-navigation {
    display: none;
}

.leftbar-description {
    display: none;
}

.leftbar-holder {
    position: static;
    text-align: left;
    transform: none;
    width: 100%;
}

.leftbar-title {
    font-size: 1.5rem;
    float: left;
    padding: 0 2rem 0 1rem;
    height: 100%;
    line-height: 3rem;
}

}